/* ==================================
Defining global variables
=================================== */

/* @import url('https://fonts.googleapis.com/css?family=Crimson+Text:400,400i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Sen:400,700,800');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,700&display=swap'); */

:root {
    --blue: #22C8FF;
    --orange: #FFB400;
    --greay: #78909C;
    --serif: 'Crimson Text', serif;
    --sans: 'Work Sans','Sen','Lato', sans-serif;
}

.img-responsive {
    max-width: 100%;
    height: auto;
    display: block;
}


html {
    overflow-x:hidden !important;
    background:rgba(50,50,50,0.025);
}



.active-a {
  color:var(--orange) !important;
}

/* ==================================
Defining grid
=================================== */

html {
    box-sizing: border-box;
}

body {
    margin-left: auto;
    margin-right: auto;
    /*background:rgba(50,50,50,0.025);*/
}

*, *:before, *:after {
    box-sizing: inherit;
}

.parent-container {
    /*background-color: pink;*/
    /*max-width: 1140px;*/
    max-width:1280px;
    /* max-width:1400px; */
    margin-right: auto;
    margin-left: auto;
    /* does this need to be used? */
    padding-left: 7%;
    padding-right: 7%;
}

.row:before, .row:after {
    content: "";
    display: table;
    clear: both;
}

.outlined {
    border: 1px darkgrey solid;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
    /*original padding: 10px */
    padding-right: 15px;
    padding-left: 15px;
    float: left;
    /*border: 1px solid black;*/
}

.col-1 {
    width: 8.33333%;
}

.col-2 {
    width: 16.666667%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.3333%;
}

.col-5 {
    width: 41.6667%;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 58.333%;
}

.col-8 {
    width: 66.66667%;
}

.col-9 {
    width: 75%;
}

.col-10 {
    width: 83.333%;
}

.col-11 {
    width: 91.6667%;
}

.col-12 {
    width: 100%;
}


/* ================================
    offset grid features
    ============================= */

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.666667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4, .offset-41 {
    margin-left: 33.3333%;
}

.offset-5 {
    margin-left: 41.6667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.333%;
}

.offset-11 {
    margin-left: 91.6667%;
}

.inner-grid {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.column {
    width: calc((100% - 20px * 12) / 12);
    height: 80px;
    margin-right: 10px;
    margin-left: 10px;
    background: rgba(0, 0, 255, 0.25);
    float: left;
}


/* ==================================
Defining Typography
=================================== */


/* ==================================
Responsive design - defining breakpoints
=================================== */

@media only screen and (max-width: 320px) {
    .col-1 {
        width: 100%;
    }
    .col-2 {
        width: 100%;
    }
    .col-3 {
        width: 100%;
    }
    .col-4 {
        width: 100%;
    }
    .col-5 {
        width: 100%;
    }
    .col-6 {
        width: 100%;
    }
    .col-7 {
        width: 100%;
    }
    .col-8 {
        width: 100%;
    }
    .col-9 {
        width: 100%;
    }
    .col-10 {
        width: 100%;
    }
    .col-11 {
        width: 100%;
    }
    .offset-1, .offset-2, .offset-3, .offset-4, .offset-41, .offset-5, .offset-6, .offset-7, .offset-8, .offset-9, .offset-10, .offset-11, .offset-12 {
        margin-left: 0;
    }
    body {
        font-size: ;
    }
    h1 {

    }
   

}

@media only screen and (max-width: 540px) and (min-width: 320px) {

    .img-img {
        width:20% !important;
    }
    .about-description {
        margin-top:50% !important;
    }
    footer {
        margin-bottom:10%;
    }
    .col-1 {
        width: 100%;
    }
    .col-2 {
        width: 100%;
    }
    .col-3 {
        width: 100%;
    }
    .col-4 {
        width: 100%;
    }
    .col-5 {
        width: 100%;
    }
    .col-6 {
        width: 100%;
    }
    .col-7 {
        width: 100%;
    }
    .col-8 {
        width: 100%;
    }
    .col-9 {
        width: 100%;
    }
    .col-10 {
        width: 100%;
    }
    .col-11 {
        width: 100%;
    }
    .offset-1, .offset-2, .offset-3, .offset-4, .offset-41, .offset-5, .offset-6, .offset-7, .offset-8, .offset-9, .offset-10, .offset-11, .offset-12 {
        margin-left: 0;
    }
    body {
        font-size: 1.1em !important;
        line-height:1.35em !important;
    }
    h1 {
        font-size:1.8em !important;
        line-height:1.15em;
    }
    h2 {
        font-size:1.45em !important;
    }
    h3 {
        font-size:1.15em !important;
    }
   
    
}

@media only screen and (max-width: 690px) and (min-width: 541px) {
    .img-img {
        width:20% !important;
    }
    .about-description {
        margin-top:40% !important;
    }
    footer {
        margin-bottom:10%;
    }
    .col-1 {
        width: 33.333%;
    }
    .col-2 {
        width: 33.333%;
    }
    .col-3 {
        width: 33.333%;
    }
    .col-4 {
        width: 100%;
    }
    .col-5 {
        width: 100%;
    }
    .col-6 {
        width: 100%;
    }
    .col-7 {
        width: 100%;
    }
    .col-8 {
        width: 100%;
    }
    .col-9 {
        width: 100%;
    }
    .col-10 {
        width: 100%;
    }
    .col-11 {
        width: 100%;
    }
    .col-12 {
        width: 100%;
    }
    .offset-1, .offset-2, .offset-3, .offset-4, .offset-5 {
        margin-left: 0%;
    }
    .offset-6, .offset-7, .offset-8, .offset-8, .offset-9 {
        margin-left: 50%;
    }
    .offset-10, .offset-11, .offset-12 {
        margin-left: 65%;
    }

    /* body {
        font-size: 1.1em !important;
        line-height:1.35em !important;
    }
    h1 {
        font-size:1.8em !important;
    }
    h2 {
        font-size:1.45em !important;
    }
    h3 {
        font-size:1.15em !important;
    } */
   
}

@media only screen and (max-width: 768px) and (min-width: 690px) {
    .img-img {
        width:15% !important;
    }
    .about-description {
        margin-top:35% !important;
    }
    footer {
        margin-bottom:5%;
    }
    .col-1 {
        width: 33.333%;
    }
    .col-2 {
        width: 50%;
    }
    .col-3 {
        width: 50%;
    }
    .col-4 {
        width: 100%;
    }
    .col-5 {
        width: 100%;
    }
    .col-6 {
        width: 100%;
    }
    .col-7 {
        width: 100%;
    }
    .col-8 {
        width: 100%;
    }
    .col-8.hovering {
        width: 100%;
    }
    .col-9 {
        width: 100%;
    }
    .col-10 {
        width: 100%;
    }
    .col-11 {
        width: 100%;
    }
    .col-12 {
        width: 100%;
    }
    .offset-1, .offset-2, .offset-3, .offset-4, .offset-41, .offset-5 {
        margin-left: 0%;
    }
    .offset-6, .offset-7, .offset-8, .offset-8, .offset-9 {
        margin-left: 50%;
    }
    .offset-10, .offset-11, .offset-12 {
        margin-left: 65%;
    }

    /* body {
        font-size: 1.15em !important;
        line-height:1.35em !important;
    }
    h1 {
        font-size:1.8em !important;
    }
    h2 {
        font-size:1.45em !important;
    }
    h3 {
        font-size:1.23em !important;
    }
    */
}

@media only screen and (max-width: 810px) and (min-width: 768px) {
    .img-img {
        width:10% !important;
    }
    .col-1 {
        width: 33.333%;
    }
    .col-2 {
        width: 50%;
    }
    .col-3 {
        width: 50%;
    }
    .col-4 {
        width: 100%;
    }
    .col-5 {
        width: 100%;
    }
    .col-6 {
        width: 100%;
    }
    .col-7 {
        width: 100%;
    }
    .col-8 {
        width: 100%;
    }
    .col-8.hovering {
        width: 100%;
    }
    .col-9 {
        width: 100%;
    }
    .col-10 {
        width: 100%;
    }
    .col-11 {
        width: 100%;
    }
    .col-12 {
        width: 100%;
    }
    .offset-1, .offset-2, .offset-3, .offset-4, .offset-41, .offset-5 {
        margin-left: 0%;
    }
    .offset-6, .offset-7, .offset-8, .offset-8, .offset-9 {
        margin-left: 50%;
    }
    .offset-10, .offset-11, .offset-12 {
        margin-left: 65%;
    }
    /* body {
        font-size: 1.17em !important;
        line-height:1.3em !important;
    }
    h1 {
        font-size:2.3em !important;
    }
    h2 {
        font-size:1.8em !important;
    }
    h3 {
        font-size:1.25em !important;
    } */
    
}

@media only screen and (max-width: 900px) and (min-width: 810px) {
   .img-img {
        width:10% !important;
    }
    /* body {
        font-size: 1.17em !important;
        line-height:1.3em !important;
    }
    h1 {
        font-size:2.3em !important;
    }
    h2 {
        font-size:1.8em !important;
    }
    h3 {
        font-size:1.25em !important;
    } */
}

@media only screen and (max-width: 970px) and (min-width: 900px) {
    /* body {
        font-size: 1.17em !important;
        line-height:1.3em !important;
    }
    h1 {
        font-size:2.3em !important;
    }
    h2 {
        font-size:1.8em !important;
    }
    h3 {
        font-size:1.25em !important;
    } */
}

@media only screen and (max-width: 1200px) and (min-width: 970px) {
    /* body {
        font-size: 1.17em !important;
        line-height:1.3em !important;
    }
    h1 {
        font-size:2.3em !important;
    }
    h2 {
        font-size:1.8em !important;
    }
    h3 {
        font-size:1.25em !important;
    } */
}

@media only screen and (min-width: 1200px){
    /* body {
        font-size: 1.2em !important;
        line-height:1.45em !important;
    }
    h1 {
        font-size:2.5em !important;
    }
    h2 {
        font-size:2em !important;
    }
    h3 {
        font-size:1.3em !important;
    } */
}