@import url('https://fonts.googleapis.com/css?family=Crimson+Text:400,400i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Sen:400,700,800');
@import url('https://fonts.googleapis.com/css?family=Work+Sans:300,400,500,600,700&display=swap');

@font-face {
  font-family: 'Ogg Light';
  src: local('Ogg-Light'), url(./assets/Ogg/Ogg-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Ogg Roman';
  src: local('Ogg-Roman'), url(./assets/Ogg/Ogg-Roman.woff) format('woff2');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
    font-family: 'Work Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --blue: #22C8FF;
  --orange: #FFB400;
  --greay: #78909C;
  --serif: 'Crimson Text', serif;
  --sans: 'Work Sans','Sen','Lato', sans-serif;
  --scale: 1.3;
}



html {
  /* font-family: var(--sans), Lato, sans-serif; */
  background-color:pink;
}
/* ==================================
Navbar
=================================== */

.break {
  height:200px;
  width:100%;
}

.break2 {
  height:100px;
  width:100%;
}

.break3 {
  height:50px;
  width:100%;
}

.break4 {
  height:25px;
  width:100%;
}
/* 
#navbar {
  padding-top: 1.2em;
  padding-bottom: 0.5em;
  font-family: var(--sans), Lato, sans-serif;
  font-size: 1.35em;
  top:0;
  position: fixed; 
  width:100%;
  transition: top 0.3s; 
  z-index:1;

}

#navbar a {
  color: black;
  text-decoration: none;
}
.img-img {
  width: 7%;
  float: left !important;
  margin-bottom: 1em;
  clear: both;
}

.to_about, .to_research, .to_design, .to_about_selected, .to_research_selected, .to_design_selected {
  font-size:17px;
  margin-left:20px;
}

.to_about_selected {
  text-decoration: underline !important;
}

.to_research_selected {
  text-decoration: underline !important;
}

.to_design_selected {
  text-decoration: underline !important;
}


.to_home:hover, .to_research:hover, .to_about:hover {
  opacity:0.6;
  -webkit-transition:  200ms ease-in-out;
  -moz-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
} */

/* ==================================
Text
=================================== */

h1 {
  font-family: "Ogg Roman";
  letter-spacing:0.01em;
  /* font-size: 2.4em; */
  font-size: 3em;
  /* font-size:60pt; */
  line-height:135%
}

h2 {
  font-family: "Ogg Roman";
  font-size:2.4em;
  /* font-size:1.9em; */
  /* font-size:48pt; */
  letter-spacing:0.015em;
  @media screen and (min-width: 320px) and (max-width: 600px) {
    line-height:110%;
    /* background:red; */
  }
}

h3 {
  font-family: 'Work Sans';
  font-weight: 600;
  opacity:50%;
  font-size:1.5em;
  /* font-size:1.8em; */
  /* font-size:36pt; */
}

h4 {
  font-family: 'Work Sans';
  font-weight: 600;
  font-size:1.2em;
  /* font-size:1.5em; */
  /* line-height:105%; */
  /* line */
  margin-top:-0vh;
  margin-bottom:0px;
  /* font-size:30pt; */
  @media screen and (min-width: 320px) and (max-width: 600px) {
    font-size:1.1em;
    /* background:red; */
  }
}

.p1 {
  font-family: 'Work Sans';
  font-size:1.2em;
  /* font-size:1.5em; */
  font-weight:400;
  line-height:125%;
  opacity:0.9;
  /* font-size:30pt; */
  @media screen and (min-width: 320px) and (max-width: 600px) {
    font-size:1em;
    /* background:red; */
  }

}

.p2 {
  font-family: 'Work Sans';
  font-size:1em;
  /* font-size:1.1em; */
  letter-spacing:0.01em;
  font-weight:400;
  line-height:133%;
  opacity:0.9;
  /* font-size: 20pt; */
  @media screen and (min-width: 320px) and (max-width: 600px) {
    font-size:0.8em;
    /* background:red; */
  }
}

.p3 {
  font-family: 'Work Sans';
  font-style: italic;
  /* font-size:0.6em; */
  font-size:0.8em;
  /* font-size: 16pt; */
}

.p4 {
  font-family: 'Work Sans';
  opacity:0.6;
  font-size:0.8em;
  text-transform:uppercase;
  /* letter-spacing:105%; */
  margin-top:3vh;
}

.p5 {
  font-family: 'Work Sans';
  opacity:0.6;
  font-size:0.8em;
  text-transform:uppercase;
  margin-bottom:-2vh;
}


.a1 {
  color: var(--blue);
  text-decoration: underline;
  /* font-size:30pt; */
  font-weight:500;
}

a .disabled {
  opacity:0.5;
  color:var(--greay);
}

.a1:hover {
  color: var(--greay);
  text-decoration: underline;
}

p .a2 {
  font-family: 'Work Sans';
  font-weight: 300;
  color:#000;
  opacity:50%;
  text-decoration:none;
}

p .a2:hover {
  text-decoration: underline;
}

.a3 {
  text-decoration:none !important;
  /* text-decoration:underline; */
  color:#000;
}



/* ==================================
Homepage elements
=================================== */


.img-placeholder {
  height:300px;
  Width:100%;
  background-color:blue;
}

.home-img {
  min-height:300px;
  max-height:300px;
  width:100%;
  object-fit: cover;
}

.no-margin {
  padding-left:0 !important;
}

.home-project-caption{
  padding-left:0 !important;
  margin-left:0 !important;
  margin-top:-10px;
}

.homepage_element img{
  height:60%;
  width:100%;
  object-fit: cover;
}

.homepage_element {
  margin-top:10%;
  margin-bottom:5%;
}


.about-description {
  margin-top:30% ;
}

/* h3 a{
  text-decoration: none;
  color:black;
}

h3 a:hover {
  color:var(--blue);
  -webkit-transition:  350ms ease-in-out;
  -moz-transition: 350ms ease-in-out;
  -o-transition: 350ms ease-in-out;
  transition: 350ms ease-in-out;
}

p a, li a {
  text-decoration: none;
  color:var(--greay);
}

p a:hover, li a:hover {
  color:var(--blue);
  -webkit-transition:  350ms ease-in-out;
  -moz-transition: 350ms ease-in-out;
  -o-transition: 350ms ease-in-out;
  transition: 350ms ease-in-out;
} */

/* ==================================
page elements
=================================== */


.img-marginalized {
  margin-top:3%;
  margin-bottom:3%;
}

.img-page-top {
/*    height:50%;
  width:150%;
  overflow: hidden;
  background-position:center;
  background-size:cover;*/
  
}

.fadein {
  animation:fadeIn;
  animation-duration:2s;
  background:blue;
  opacity:1;
}

.half-img {
  padding-top:2%;
  padding-bottom:2%;
  /*background:red;*/
}

.half-img-container {
  padding-top:3%;
  padding-bottom:3%;
}

.project_divider {
  margin-top:3%;
  margin-bottom:3%;
  opacity:0.3;
}
/* ==================================
footer elements
=================================== */

.footer-link {
  text-decoration:none;
  /* font-size:0.8em; */
  color: var(--greay);
  /* margin-right: 2%; */
  display:inline;
}

.footer-link ul {
  display:inline-block;
  padding-right:50px;
  margin-left:0px;
  list-style-position: inside;
  padding-left: 0;
}

.footer-link a {
  margin-left:0;
  padding-left:0px;
}

.footer {
  margin-top:50px;
  /* position:absolute; */
  clear:both;
  padding-top:15%;
  padding-bottom: 2.5%;
  padding-left: 0;
  /*margin-bottom:10%;*/
}